import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../seo.service';

export interface Contacts {
  fullName: string;
  address: string;
  function: string;
  party?: string;
  telephone?:string;
}

const CONTACTS_DATA: Contacts[] = [
  {fullName: 'Egon Eckhardt', address: 'Schulstraße 2', function: 'Ortsbürgermeister', party: 'SPD'},
  {fullName: 'Michael Schneider', address: 'Römerberg 23', function: 'Fraktionssprecher CDU', party: 'CDU'},
  {fullName: 'Bernd Kissel', address: 'Römerberg 9', function: 'Fraktionssprecher FWG', party: 'FWG'},
  {fullName: 'René Schröder', address: 'Hauptstraße 11', function: 'Fraktionssprecher SPD', party: 'SPD'},
  {fullName: 'Thomas Bendlage', address: 'Herrengarten 10', function: 'stv. Fraktionssprecher CDU', party: 'CDU'},
  {fullName: 'Helmut Faber', address: 'Nachtigallenweg 10', function: 'stv. Fraktionssprecher FWG', party: 'FWG'},
  {fullName: 'Katharina Hartwein', address: 'Hahnenstraße 4', function: 'stv. Fraktionssprecherin SPD', party: 'SPD'},
  {fullName: 'Iris Buch', address: 'Nahestraße 2a', function: '1. Beigeordnete', party: ''},
  {fullName: 'Hubertus Fey', address: 'Römerberg 36', function: '2. Beigeordneter', party: ''},
  {fullName: 'Fethi Bayer', address: 'Wilhelmstraße 10', function: '3. Beigeordneter', party: ''},
  {fullName: 'Frank Buch', address: 'Hunolsteiner Straße 5', function: '', party: ''},
  {fullName: 'Jörg Ackermann', address: 'Großstraße 36', function: '', party: ''},
  {fullName: 'Burkhard Hubert', address: 'Bruchgasse 1', function: '', party: ''},
  {fullName: 'Willi Richter', address: 'Römerberg 22', function: '', party: ''},
  {fullName: 'Bruno Klee', address: 'Bruchgasse 2', function: '', party: ''},
  {fullName: 'Stefan Ottenbreit', address: 'Unterm Schloss 17a', function: '', party: ''},
  {fullName: 'Nicola Rosenow', address: 'Bachstraße 85', function: '', party: ''},
  {fullName: 'Bernd Keller', address: 'Römerberg 40', function: '', party: ''},
  {fullName: 'Martin Bock', address: 'Nahestraße 10', function: '', party: ''},
];

const ADDITIONAL_CONTACTS_DATA: Contacts[] = [
  {fullName: 'Bruno Schneider', address: 'Berliner Straße 14a, 55566 Bad Sobernheim', telephone: '0171 3320846', function: 'Jagdgenossenschaft'},
  {fullName: 'Karl Caesar', address: 'An der Trift, 55627 Merxheim', telephone: '06754 8326', function: 'Bauern- und Winzergenossenschaft'},
  {fullName: '', address: 'Hauptstraße 67, 55627 Merxheim', telephone: '06754 8611', function: 'Kindergarten'},
]

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  displayedColumns: string[] = ['fullName', 'address', 'function', 'party'];
  displayedColumnsAC: string[] = ['function', 'address', 'fullName', 'telephone'];
  dataSource = CONTACTS_DATA;
  dataSourceAC = ADDITIONAL_CONTACTS_DATA;

  constructor(
    private seoService:SeoService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }

}
