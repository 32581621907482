import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ClubsComponent } from './clubs/clubs.component';
import { CompaniesComponent } from './companies/companies.component';
import { ContactsComponent } from './contacts/contacts.component';
import { EventsComponent } from './events/events.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { metaData } from './metadata';
import { NewsComponent } from './news/news.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: metaData.home },
  { path: 'termine', component: EventsComponent, data: metaData.events },
  { path: 'aktuelles', component: NewsComponent, data: metaData.news },
  { path: 'vereine', component: ClubsComponent ,data: metaData.clubs },
  { path: 'gewerbe', component: CompaniesComponent ,data: metaData.companies},
  { path: 'uebermerxheim', component: HistoryComponent ,data: metaData.history},
  { path: 'kontakt', component: ContactsComponent ,data: metaData.contacts},
  { path: 'impressum', component: AboutComponent ,data: metaData.about },
  { path: 'datenschutz', component: PrivacyComponent ,data: metaData.privacy },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
