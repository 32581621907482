<div class="news-content">
    <mat-card class="news-card" style="flex: 0 0 40vw;">
        <div>08.07.2024</div>
        <mat-card-title>Wahlergebnisse</mat-card-title>
        <mat-card-subtitle>Europa- und Kommunalwahlen 2024</mat-card-subtitle>
        <mat-card-content>
            <p>
                Für all diejenigen, die die Wahlergebnisse der vergangenen Wochen einsehen möchten, 
                wollen wir hiermit noch auf die <a href="https://www.vg-nahe-glan.de/aktuelles/wahlergebnisse/" target="_blank">Seite der Verbandsgemeinde</a> aufmerksam machen.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card>
    <mat-card class="news-card" style="flex: 0 0 40vw;">
        <div>08.05.2024</div>
        <mat-card-title>Ausbau Ortsdurchfahrt L 232</mat-card-title>
        <mat-card-subtitle>Bürgerinformationsveranstaltung vom 30.04.2024</mat-card-subtitle>
        <mat-card-content>
            <p>
                Letzen Dienstag fand die Bürgerinformationsveranstaltung zum Ausbau der Ortsdurchfahrt L 232 entlang der Merxheimer Hauptstraße statt. <br><br>
                Beginn der Baumaßnahme war vor zwei Tagen, am 06.05.2024. <br>
                Die Fertigstellung ist auf den 23.12.2025 datiert. <br><br>
                Weitere Informationen zum Thema können <a href="../../assets/files/Ausbau_L232.pdf" target="_blank">hier</a> eingesehen werden.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card>
    <mat-card class="news-card" style="flex: 0 0 30vw;">
        <div>18.12.2023</div>
        <mat-card-title>Veranstaltungskalender der Ortsgemeinde</mat-card-title>
        <mat-card-subtitle>Jetzt online verfügbar!</mat-card-subtitle>
        <mat-card-content>
            <p>
                Auch in diesem Jahr veröffentlichen wir hiermit den <a href="../../assets/files/Veranstaltungskalender_24:25.pdf" target="_blank">Veranstaltungskalender</a> der Ortsgemeinde Merxheim mit allen Events für das kommende Jahr 2024 und Anfang 2025! <br><br>
                Wir freuen uns auf euch.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card>
    <!--mat-card class="news-card" style="flex: 0 0 20vw;">
        <div>24.03.2023</div>
        <mat-card-title>Hochwasservorsorge</mat-card-title>
        <mat-card-subtitle>Vorsorgekonzept der Ortsgemeinde Merxheim</mat-card-subtitle>
        <mat-card-content>
            <p>
                Die ehemaligen Verbandsgemeinden Bad Sobernheim und Meisenheim ließen und lassen für Ihre Gemeinden Hochwasser- und Starkregenvorsorgekonzepte erstellen.
                Für viele Gemeinden, darunter auch die Ortsgemeinde Merxheim, wurden die Konzepte bereits auf der VG Nahe-Glan Webseite veröffentlicht. <br><br>  
                Das für die Ortsgemeinde Merxheim entwickelte Konzept lässt sich <a href="https://www.vg-nahe-glan.de/bauen-wohnen-umwelt/hochwasservorsorge/vorsorgekonzepte-der-gemeinden/#accordion-1-10" target="_blank">hier</a> einsehen.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card>
    <mat-card class="news-card">
        <div>05.03.2023</div>
        <mat-card-title>Hochwasservorhersagedienst Rheinland-Pfalz</mat-card-title>
        <mat-card-subtitle>Neuer Webauftritt</mat-card-subtitle>
        <mat-card-content>
            <p>
                Der neue Webauftritt des Hochwasservorhersagedienstes Rheinland-Pfalz ist nun öffentlich freigeschaltet und
                unter der Adresse <a href="https://hochwasser.rlp.de">hochwasser.rlp.de</a> erreichbar.
            </p>
            <p>
                Eine entsprechende Pressemitteilung des Klimaschutzministeriums kann 
                <a href="https://mkuem.rlp.de/de/pressemeldungen/detail/news/News/detail/katrin-eder-hochwassermeldungen-muessen-verstaendlich-aktuell-und-digital-sein/" target="_blank">hier</a> eingesehen werden.
            </p>
            <p> 
                Im Youtube-Kanal des Landesamts für Umwelt Rheinland-Pfalz findest Du ein Video mit einer Kurzeinführung zum neuen Webauftritt.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card>
    <mat-card class="news-card" style="flex: 0 0 30vw;">
        <div>01.03.2023</div>
        <mat-card-title>Veranstaltungskalender der Ortsgemeinde</mat-card-title>
        <mat-card-subtitle>Jetzt online verfügbar!</mat-card-subtitle>
        <mat-card-content>
            <p>
                Auch in diesem Jahr veröffentlichen wir hiermit den <a href="../../assets/files/Veranstaltungskalender_23:24.pdf" target="_blank">Veranstaltungskalender</a> der Ortsgemeinde Merxheim mit allen Events für das laufende Jahr 2023 und Anfang 2024! <br><br>
                Wir freuen uns auf euch.
            </p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
    </mat-card-->
</div>
