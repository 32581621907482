export const metaData = {
    home: {
        meta: {
            title: "Herzlich Willkommen in Merxheim an der Nahe",
            description: "Die lebenswerte Gemeinde Merxheim an der Nahe, zwischen der Edelsteinmetropole Idar-Oberstein und der Kurstadt Bad Kreuznach."
        }
    },
    history: {
        meta: {
            title: "Über Merxheim",
            description: "Die idyllisch gelegene Gemeinde Merxheim findet man an der „Naheweinstraße“ und der „Deutschen Baum- und Alleenstraße“ gelegen. Mit seinen rd. 1500 Einwohnern lädt die Gemeinde zu einem kennenlernen ein."
        }
    },
    events: {
        meta: {
            title: "Veranstaltungen",
            description: "Ob Sommer oder Winter, Frühling oder Herbst. In Merxheim findet über das Jahr hinweg eine Vielzahl an Events statt. Sei auch Du dabei, wenn es wieder soweit ist."
        }
    },
    contacts: {
        meta: {
            title: "Kontakt",
            description: "Gemeinderatsmitglieder und sonstige Kontakte der Ortsgemeinde Merxheim."
        }
    },
    companies: {
        meta: {
            title: "Gewerbe",
            description: "In Merxheim gibt es eine Vielzahl an Unternehmen und Gewerbetreibenden, die jedem gerne mit Rat und Tat zur Seite stehen. Ob bei der Planung Deines neuen Eigenheims oder der nächsten Frisur, hier wird jedem geholfen."
        }
    },
    clubs: {
        meta: {
            title: "Vereine",
            description: "Merxheim bietet jedem ein breit gefächertes Angebot an Vereinen. Ob Du Dich lieber beim Fußball auspowern, oder die Ruhe beim Angeln genießen möchtest - hier ist für jeden etwas dabei. Werde auch Du Mitglied in einem der Merxheimer Vereine."
        }
    },
    news: {
        meta: {
            title: "Aktuelles",
            description: "Auch in einem Dorf wie Merxheim gibt es stets über Neuigkeiten zu berichten. Hier bist Du genau richtig, um stets die aktuellsten Informationen auf einen Blick zu erhalten."
        }
    },
    about: {
        meta: {
            title: "Impressum",
            description: "Angaben gemäß §5 TMG Ortsgemeinde Merxheim Schulstraße 2 55627 Merxheim; Kontakt Telefon 06754 665 E-Mail: bgm@merxheim.de; Haftung für Inhalte Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen."
        }
    },
    privacy: {
        meta: {
            title: "Datenschutz",
            description: "Datenschutzerklärung Einleitung Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als Daten bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als Onlineangebot)."
        }
    } 
}
