export interface Event {
    title: string;
    subtitle: string;
    description: string;
    location: string;
    date: string;
    time: string;
    weekDay: string;
    imageSource: string;
}

export const events:Event[] = [
    {
        date: "31.05.23",
        weekDay: "Mi.",
        time: "19:00 Uhr",
        title: "Haupt- und Finanzausschusssitzung",
        subtitle: "Haupt- und Finanzausschusssitzung des Ortsgemeinderates",
        description: `
            Hiermit laden wir recht herzlich zur diesjährigen Haupt- und Finanzausschusssitzung des Ortsgemeinderates ein.<br><br>
            Die Sitzung findet im Mehrzweckraum der Mehrzweckhalle statt. <br><br>
            Wir freuen uns auf euer Kommen.
        `,
        location: "Mehrzweckhalle Merxheim",
        imageSource: "../../assets/img/wappen_merxheim.png"
    },
    {
        date: "07.06.23",
        weekDay: "Mi.",
        time: "19:00 Uhr",
        title: "Sitzung des Ortsgemeinderates",
        subtitle: "Einladung zur Sitzung des Ortsgemeinderates",
        description: `
            Hiermit laden wir recht herzlich zu einer weiteren Sitzung des Ortsgemeinderates ein.<br><br>
            Die Sitzung findet im Mehrzweckraum der Mehrzweckhalle statt. <br><br>
            Wir freuen uns auf euer Kommen.
        `,
        location: "Mehrzweckhalle Merxheim",
        imageSource: "../../assets/img/wappen_merxheim.png"
    }
]

export function upcomingEvents(events:Event[]): Event[] {
    let upcomingEvents:Event[] = [];

    for (let event of events) {
        const eventDateSplit = event.date.split(".");
        const eventDate = new Date(`${eventDateSplit[1]}-${eventDateSplit[0]}-${eventDateSplit[2]}`);
        const today = new Date();
        today.setHours(0,0,0,0);

        if (eventDate >= today) {
            upcomingEvents.push(event);
        }
    }

    return upcomingEvents;
}