<mat-toolbar fxShow fxHide.lt-md class="navbar">
  <div class="item">
    <div [routerLink]="['/']">
      <span class="navbar-title">Merxheim</span>
      <span class="navbar-subtitle">an der Nahe</span>
    </div>
    <img class="logo" src="../assets/img/wappen_merxheim.png">
  </div>
  <div>
    <p [routerLink]="['/aktuelles']" routerLinkActive="navbar-link-active"  class="navbar-link">NEWS</p>
    <p [routerLink]="['/termine']" routerLinkActive="navbar-link-active" class="navbar-link">TERMINE</p>
    <p [routerLink]="['/vereine']" routerLinkActive="navbar-link-active"  class="navbar-link">VEREINE</p>
    <p [routerLink]="['/gewerbe']" routerLinkActive="navbar-link-active"  class="navbar-link">GEWERBE</p>
    <p [routerLink]="['/uebermerxheim']" routerLinkActive="navbar-link-active"  class="navbar-link">ÜBER MERXHEIM</p>
    <p [routerLink]="['/kontakt']" routerLinkActive="navbar-link-active"  class="navbar-link">KONTAKT</p>
  </div>
</mat-toolbar>
<div class="container" fxHide fxShow.lt-md>
  <mat-toolbar class="navbar">
    <img class="logo" src="../assets/img/wappen_merxheim.png" [routerLink]="['/']" (click)="snav.close()">
    <button mat-icon-button *ngIf="!snav.opened" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <button mat-icon-button *ngIf="snav.opened" (click)="snav.toggle()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav [opened]="false" mode="over" class="sidenav-container" [fixedInViewport]="mobileQuery.matches">
      <mat-nav-list>
        <a (click)="snav.close()" [routerLink]="['/aktuelles']" routerLinkActive="navbar-link-active"  class="navbar-link">NEWS</a>
        <a (click)="snav.close()" [routerLink]="['/termine']" routerLinkActive="navbar-link-active" class="navbar-link">TERMINE</a>
        <a (click)="snav.close()" [routerLink]="['/vereine']" routerLinkActive="navbar-link-active"  class="navbar-link">VEREINE</a>
        <a (click)="snav.close()" [routerLink]="['/gewerbe']" routerLinkActive="navbar-link-active"  class="navbar-link">GEWERBE</a>
        <a (click)="snav.close()" [routerLink]="['/uebermerxheim']" routerLinkActive="navbar-link-active"  class="navbar-link">ÜBER MERXHEIM</a>
        <a (click)="snav.close()" [routerLink]="['/kontakt']" routerLinkActive="navbar-link-active"  class="navbar-link">KONTAKT</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<router-outlet></router-outlet>
<footer>
  <div class="main-footer">
    <section class="footer-contacts">
      <h3>Kontakt</h3>
      <a href="tel:+496754665"><mat-icon>phone</mat-icon> 06754 665</a>
      <a href="tel:+491712833850"><mat-icon>phone</mat-icon> 0171 28 33 850</a>
      <a href="mailto:bgm@merxheim.de"><mat-icon>mail</mat-icon> bgm@merxheim.de</a>
    </section>
    <section>
      <h3>Links</h3>
      <p [routerLink]="['/impressum']" class="footer-link">Impressum</p>
      <p [routerLink]="['/datenschutz']" class="footer-link">Datenschutz</p>
    </section>
    <section class="company">
      <div>
        <span class="name">Merxheim</span>
        <span class="sub-name">an der Nahe</span>
      </div>
      <img class="company-logo" src="../assets/img/wappen_merxheim.png">
    </section>
  </div>
  <div class="sub-footer">
    <div>&copy; 2023 Ortsgemeinde Merxheim</div>
  </div>
</footer>