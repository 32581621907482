<div class="content">
    <section>
      <h2>Unternehmen</h2>
      <p>In Merxheim gibt es eine Vielzahl an Unternehmen und Gewerbetreibenden, die jedem gerne mit Rat und Tat zur Seite stehen. Ob bei der Planung Deines neuen Eigenheims oder der nächsten Frisur, hier wird jedem geholfen.</p>
      <div>
          <mat-card *ngFor="let company of companies">
            <a href="{{ company.url }}" target="_blank">
              <mat-card-title>{{ company.name }}</mat-card-title>
              <mat-card-content>
                  <img src="{{ company.logoPath }}">
              </mat-card-content>
              <mat-card-footer>
                <mat-divider inset></mat-divider>
                <div>
                    <section>
                        <p><mat-icon>person</mat-icon>{{ company.owner }}</p>
                        <p><mat-icon>phone</mat-icon>{{ company.phone }}</p>
                    </section>
                    <section>
                        <p><mat-icon>mail</mat-icon>{{ company.mail }}</p>
                        <p><mat-icon>location_on</mat-icon>{{ company.location }}</p>
                    </section>
                </div>
              </mat-card-footer>
            </a>
          </mat-card>
      </div>
    </section>
</div>