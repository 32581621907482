<div class="content">
    <section>
        <h2>Gemeinderat</h2>
        <div class="responsive-table">

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let contact"> {{contact.fullName}} </td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef> Straße </th>
                  <td mat-cell *matCellDef="let contact"> {{contact.address}} </td>
                </ng-container>
                <ng-container matColumnDef="function">
                  <th mat-header-cell *matHeaderCellDef> Funktion </th>
                  <td mat-cell *matCellDef="let contact"> {{contact.function}} </td>
                </ng-container>
                <ng-container matColumnDef="party">
                  <th mat-header-cell *matHeaderCellDef> Fraktion </th>
                  <td mat-cell *matCellDef="let contact"> {{contact.party}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </div>
        </section>
        <section>
            <h2>Sonstige Kontakte</h2>
            <div class="responsive-table">
                <table mat-table [dataSource]="dataSourceAC" class="mat-elevation-z8">
                    <ng-container matColumnDef="function">
                    <th mat-header-cell *matHeaderCellDef> Organisation </th>
                    <td mat-cell *matCellDef="let contact"> {{contact.function}} </td>
                    </ng-container>
                    <ng-container matColumnDef="fullName">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let contact"> {{contact.fullName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="address">
                      <th mat-header-cell *matHeaderCellDef> Straße, Ort </th>
                      <td mat-cell *matCellDef="let contact"> {{contact.address}} </td>
                    </ng-container>
                    <ng-container matColumnDef="telephone">
                      <th mat-header-cell *matHeaderCellDef> Telefonnummer </th>
                      <td mat-cell *matCellDef="let contact"> {{contact.telephone}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAC"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAC;"></tr>
                  </table>
            </div>
    </section>
</div>