import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../seo.service';
import { Event, events, upcomingEvents } from './events';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events!:Event[];

  constructor(
    private seoService:SeoService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.events = upcomingEvents(events);

    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }
}
