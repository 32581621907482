export interface Club {
    name: string,
    mail: string,
    phone: string,
    logoPath: string,
    location: string,
    contactPerson: string,
    url?: string
}

export const clubs:Club[] = [
    {
        name: "Turnverein 1903 Merxheim",
        mail: "kontakt@turnverein-merxheim.de",
        phone: "06754 8553",
        logoPath: "../../assets/img/tv_merxheim_logo.png",
        location: "Wilhelmstraße 10, 55627 Merxheim",
        contactPerson: "Fethi Bayer",
        url: "http://turnverein-merxheim.de"
    },
    {
        name: "Angelsportverein Hecht",
        mail: "mail@asv-hecht.de",
        phone: "06754 8203",
        logoPath: "../../assets/img/asv_hecht_logo.png",
        location: "Gartenstrasse 11, 55627 Merxheim",
        contactPerson: "Heinz Fett",
        url: "http://www.asv-hecht.de/"
    },
    {
        name: "Fussballclub Viktoria Merxheim",
        mail: "oeffentlichkeitsarbeit@fcv-merxheim.de",
        phone: "06754 963301",
        logoPath: "../../assets/img/fcv_merxheim_logo.png",
        location: "Römerberg 13, 55627 Merxheim",
        contactPerson: "Andreas Grub",
        url: "https://www.facebook.com/fcvmerxheim"
    },
    {
        name: "Fastnachtsclub Merxheimer Wind",
        mail: "j.s.ackermann@online.de",
        phone: "06754 963039",
        logoPath: "../../assets/img/merxheimer_wind_logo.png",
        location: "Großstrasse 36, 55627 Merxheim",
        contactPerson: "Jörg Ackermann",
        url: "https://www.merxheimer-wind.com/"
    },
    {
        name: "Landfrauen Merxheim",
        mail: "landfrauen-merxheim@gmx.de",
        phone: "06754 7199",
        logoPath: "../../assets/img/landfrauen_logo.png",
        location: "Hauptstr. , 55627 Merxheim",
        contactPerson: "Iris Kistner",
        url: "https://landfrauenverband-meisenheim.de/index.php/merxheim"
    },
    {
        name: "Rathaus-Café Merxheim",
        mail: "rathaus-cafe@online.de",
        phone: "06754 8963",
        logoPath: "../../assets/img/rathaus_cafe_logo.png",
        location: "Großstr. 34, 55627 Merxheim",
        contactPerson: "Elke Schmidt",
        url: "http://www.rathaus-cafe-merxheim.de/"
    },
    {
        name: "FWG Merxheim",
        mail: "",
        phone: "06754 1315",
        logoPath: "../../assets/img/fwg_merxheim_logo.png",
        location: "Bruchgasse 2, 55627 Merxheim",
        contactPerson: "Gabriele Klee",
        url: "https://www.fwg-merxheim.de/"
    },
    {
        name: "SPD Ortsverein Merxheim",
        mail: "spd-merxheim@mail.de",
        phone: "0151 11620967",
        logoPath: "../../assets/img/SPD.png",
        location: "Wilhelmstraße 10, 55627 Merxheim",
        contactPerson: "Fethi Bayer",
        url: "http://facebook.com/spdmerxheim"
    }
]