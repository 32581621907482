import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../events/events';

@Component({
  selector: 'app-events-builder',
  templateUrl: './events-builder.component.html',
  styleUrls: ['./events-builder.component.scss']
})
export class EventsBuilderComponent implements OnInit {

  @Input() events!:Event[];

  constructor() { }

  ngOnInit(): void {
  }

}
