export interface Company {
    name: string;
    location: string;
    owner: string;
    phone: string;
    mail: string;
    logoPath: string;
    url? : string;
}

export const companies:Company[] = [
    {
        name: "containhair by Tanja O.",
        location: "In der Au 5, 55627 Merxheim",
        owner: "Tanja Ottenbreit",
        phone: "06754 9463377",
        mail: "info@friseur-tanja-o.de",
        logoPath: "../../assets/img/containhair_logo.jpeg"
    },
    {
        name: "jaeger bau",
        location: "Hunolsteiner Str. 40, 55627 Merxheim",
        owner: "Michael Jäger",
        phone: "06754 6903115",
        mail: "michael@jaeger-merxheim.de",
        logoPath: "../../assets/img/jaeger_bau_logo.png",
        url: "https://www.jaeger-merxheim.de/"
    },
    {
        name: "Aquaristik Service",
        location: "Römerberg 6, 55627 Merxheim",
        owner: "Ottmar Buch",
        phone: "06754 17111",
        mail: "info@aquaristik-service-buch.de",
        logoPath: "../../assets/img/aquaristik_service_logo.jpeg",
        url: "https://www.facebook.com/Zierfischservice/"
    },
    {
        name: "Gärtnerei Bock",
        location: "Nahestraße 10, 55627 Merxheim",
        owner: "Martin Bock",
        phone: "06754 313",
        mail: "info@gaertnerei-bock.de",
        logoPath: "../../assets/img/gaertnerei_bock_logo.png",
        url: "http://www.gaertnerei-bock.de/"
    },
    {
        name: "Wine & More Markus Klein",
        location: "Römerberg 5, 55627 Merxheim",
        owner: "Markus Klein",
        phone: "06754 6903393",
        mail: "info@nahe-wein.shop",
        logoPath: "../../assets/img/wine_and_more_logo.jpeg",
        url: "https://www.nahe-wein.shop/"
    },
    {
        name: "Weingut Klein",
        location: "Großstraße 41, 55627 Merxheim",
        owner: "Jürgen Klein",
        phone: "06754 472",
        mail: "info@weingut-klein.de",
        logoPath: "../../assets/img/weingut_klein_logo.jpeg",
        url: "https://www.weingut-klein.de/"
    },
]