import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title:string = 'frontend-merxheim';
  currentRoute:any;
  navbar:HTMLCollectionOf<HTMLElement>;
  mobileQuery!: MediaQueryList;
  showNavbarMobile:boolean = false;

  constructor(router:Router, media: MediaMatcher, changeDetectorRef: ChangeDetectorRef) {
    this.navbar = document.getElementsByClassName("navbar") as HTMLCollectionOf<HTMLElement>;
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    
    router.events.pipe(filter((event:any) => event instanceof NavigationEnd))
          .subscribe((event:any) => 
           {
              this.currentRoute = event.url;
          
              if (!this.mobileQuery.matches) {
                if (this.currentRoute !== "/") {
                  this.navbar[0].style.background = "#ffffff";
                  this.navbar[0].style.color = "black";
                  this.navbar[0].style.height = "80px";
                  this.navbar[0].style.position = "fixed";
                } else {
                  this.navbar[0].style.background = "#f5f5f500";
                  this.navbar[0].style.color = "#ffffff";
                  this.navbar[0].style.height = "100px";
                  this.navbar[0].style.position = "fixed";
                }
              }
           });
  }
	
	@HostListener('window:scroll', ['$event']) onScroll(e: Event): void {
    
    if (((document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) && this.currentRoute === "/" && !this.mobileQuery.matches)) {
      this.navbar[0].style.background = "#ffffff";
      this.navbar[0].style.color = "black";
      this.navbar[0].style.height = "80px";

    } else if (((document.body.scrollTop <= 80 || document.documentElement.scrollTop <= 80) && this.currentRoute === "/" && !this.mobileQuery.matches)) {
      this.navbar[0].style.background = "#f5f5f500";
      this.navbar[0].style.color = "#ffffff";
      this.navbar[0].style.height = "100px";
    }
	}

  ngOnInit(): void {
  }

}
