import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../seo.service';
import { Event, events, upcomingEvents } from '../events/events';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  events!:Event[];

  constructor(
    private seoService:SeoService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.events = upcomingEvents(events);

    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }
}
