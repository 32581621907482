<div class="content">
    <section>
        <h2>Über Merxheim</h2>
        <p>Die idyllisch gelegene Gemeinde Merxheim findet man an der „Naheweinstraße“ und der
            „Deutschen Baum- und Alleenstraße“ gelegen.
            Mit seinen rd. 1500 Einwohnern lädt die Gemeinde zu einem kennenlernen ein. Sie ist mit
            ca. 1700 ha Fläche eine der größten Flächengemeinden im <a href="https://www.bad-kreuznach.de/" target="_blank">Landkreis Bad Kreuznach</a>.<br>
            Etwas abseits der Nahe Verkehrsader „B41“ findet der Besucher Ruhe und Erholung. Das
            Freizeitgelände „Winchendeller Weiher“, wo verschiedene Grillmöglichkeiten vorhanden
            sind, kann mit den vorhandenen sanitären Anlagen, beim Ortsbürgermeister für Vereins-,
            Betriebs- und Familienfeiern angemietet werden.
            Ebenfalls bietet das Gelände einen Kinderspielplatz, der zum Toben und Spielen einlädt,
            sowie ein Wassertretbecken für die Gesundheit.
            Das Gewässer selbst wird vom ortsansässigen <a href="http://www.asv-hecht.de/" target="_blank">Angelsportverein „Hecht“</a> als Fischrevier
            gepflegt.<br>
            Weitere interessante Sehenswürdigkeiten sind das „alte Rathaus“ aus dem 15. Jahrhundert,
            welches zuletzt im Jahre 2013 General saniert wurde, sowie eine Aussichtsplattform auf dem
            „Raumberg“, wo man einen herrlichen Panoramablick in das Nahetal und die Ortsgemeinde,
            mit dem durch die Ortslage geführten Naheradweg hat.
            Zwei ausgeschilderte Wanderwege führen die Gäste durch die Gemarkung Merxheims bis
            hin zum Segelfluggelände der Meckenbacherhöhe oder auch über den Panoramaweg vorbei
            an <a href="http://www.kirschroth.de/" target="_blank">Kirschroth</a>.<br>
            In der Ortsmitte, zwischen den beiden Kirchen befindet sich ein, vor wenigen Jahren neu
            gestalteter, Dorfmittelpunkt, der „Burggraben“, der mit einem sprudelnden Brunnen und
            Sitzgelegenheiten zum Ausruhen und Verweilen einlädt.
            Die örtliche Gastronomie, mit einem Restaurant, einer sehr gepflegten Weinstube und
            Restaurant, sowie einer weiteren Gastwirtschaft sind gerne bereit Besucher, wie auch
            ortsansässige Gäste zu bewirten und zu verwöhnen.<br>
            Urkundlich erstmals im Jahre 1061 erwähnt, feierte die Gemeinde im Jahr 2011 ein großes
            Fest im Rahmen der 950 Jahr Feier. Diese Veranstaltung, die am Pfingstwochenende 2011
            stattfand, ist noch heute Gespräch in der Ortsgemeinde und weit darüber hinaus. An den
            beiden Festtagen waren mehr als 10.000 Gäste in der Gemeinde zu Besuch und alle wurden
            sehr gut versorgt und fühlten sich wohl.<br>
            Mit seinen vielen <a href="/clubs">Vereinen</a>, Turn- und Sportverein, Landfrauen, Karnevalverein,
            Jugendverein, Angelsportverein, Gesangvereine, Theaterverein und einigen mehr bietet die
            Gemeinde ein großes Freizeit- und Sportangebot für Jung und Alt.
            Ein Kindergarten findet sich ebenso in der Ortsgemeinde, wie auch eine <a href="https://www.naheland-apotheke-merxheim.de/">Apotheke</a>, eine
            Tierarztpraxis und eine Bäckerei.
            Hausärzte sind ebenfalls in unmittelbarer Umgebung, ca.5 km Entfernung, zu finden.
            Das sehr schön gelegene Neubaugebiet „Hunolsteiner Straße“, wird zur Zeit, in einem
            weiteren Bauabschnitt, mit ca. 30 Bauplätzen neu erschlossen.
            Eine schnelle Internetverbindung (Glasfaser) steht in der Gemeinde ebenfalls zur Verfügung.
            Linienbusse verkehren mehrmals täglich in Richtung <a href="https://www.kirn.de/" target="_blank">Kirn</a> und <a href="https://stadt-bad-sobernheim.de/" target="_blank">Bad Sobernheim</a>.</p>
    </section>
    <section class="bg-light-grey">
        <img src="../../assets/img/wappen_merxheim.png">
        <div>
            <h3 style=>Das Wappen</h3>
            <p>Über goldenem Schildfuß, darin ein roter Balken über drei Schindeln, von Rot und Silber gespalten. 
                Vorne ein silberner Schrägbalken belegt mit drei roten Rosen, hinten ein roter Burgturm mit Krüppelwalmdach.
            </p>
            <p><b>Begründung:</b> In einer Urkunde des Erzbischof Udo von Trier vom Jahre 1075 erscheint bereits ein 
               Albert von Merkedesheim als Zeuge. 1437 führt Rorich von Merxheim in Rot ein mit drei roten Rosen 
               belegten silbernen Schrägrechtsbalken. Zuvor im Jahre 1128 sind Rorich und Gerlach von Merxheim 
               Zeuge in einer Urkunde des Erzbischof Adelbert von Mainz. Auf dem Emblem, das Rorich von Merxheim 1437 führt, 
               ist im ersten Feld des Gemeindewappens zurückgegriffen. Die Vögte von Hunolstein hatten die längste 
               Zeit die Ortsherrschaft inne. Sie waren auch Inhaber der Herrschaft Merxheim. Ihr gemindertes Wappen 
               ist daher im Fußschild wiedergegeben. Das Wahrzeichen von Merxheim ist der als „Nürnberger“ bezeichnete 
               und bekannte Turm. Er bestimmt das Ortsbild seit Jahrhunderten. Im Feld zwei des Gemeindewappens ist er 
               daher in stilisierter Form, mit dem ihm eigentümlichen Krüppelwalmdach, aufgenommen.
            </p>
        </div>
    </section>
    <section>
        <h3>Etwas Geschichte</h3>
        <p>In der bislang ältesten Urkunde (1061) schenkte Erzbischof Eberhard von Trier das Gut „Merkedesheim“ 
            dem St. Simon-Stift in Trier. In der Beschäftigungsurkunde von Bischof Adalbert von Mainz erscheint 
            erstmals der Name Merxheim.<br>
            1350 wird ein Ritter Conrad von Merxheim erwähnt. Seine beiden Kinder, 
            Rorich und Adelheid teilten sich insbesonders in Merxheim die väterlichen Allodialgüter einen Teil des 
            „Schlosses“ und Dorfes auf. Damit kam die eine Hälfte von Merxheim als allodialer und reichsunmittelbarer 
            Besitz an das Geschlecht der Vögte von Erbfolgekrieg (1503-1504) plünderte das Heer des Herzogs von 
            Zweibrücken den Ort. Pest forderte innerhalb von 4 Monaten 228 Todesopfer.<br>
            Die Französische Revolution 
            beendete die Herrschaft der Ortsherren. Das Schloss wurde von der katholischen Kirche erworben und diente
            seit der Zeit als Gotteshaus. Nach den Franzosen kam Merxheim 1815 an Preußen und 1816 bis 1966 unter 
            hessen-homburgisches Oberhoheit. Weitere Katastrophen prägten die Geschichte des Ortes, wie z.B. 1778 
            und 1788 schwere Wolkenbrüche, die durch die Schlammflut ganze Häuser zum Einsturz brachten.<br>
            Die bislang schwerste Katastrophe in der Geschichte des Ortes ereignete sich am 24.07.1870. Spielende Kinder entfachten 
            in einer Scheune Feuer, welches einen Großbrand auslöste. Innerhalb von 3 Stunden wurden 107 Wohnhäuser mit 
            den dazugehörenden Wirtschaftsgebäuden ein Raub der Flammen. Der alte „Nürnberger Turm“, der Jahrhunderte 
            hindurch als Wahrzeichen des Ortes galt, brannte völlig aus und wurde später abgetragen. Auch die evangelische 
            Kirche fiel dem Band zu Opfer, wurde jedoch an gleicher Stelle später wieder neu aufgebaut.<br>
            1935 erfolgte in der Verwaltungszugehörigkeit wiederum ein Wechsel. Das Amt <a href="https://weindorf-meddersheim.de" target="_blank">Meddersheim</a> wurde zunächst in Personalunion 
            und ab 1940 endgültig dem Amt Sobernheim zugeteilt. Seit der rheinland-pfälzischen Verwaltungsreform 1970 
            gehört Merxheim zur <a href="https://stadt-bad-sobernheim.de/" target="_blank">Verbandsgemeinde Bad Sobernheim</a>
            und mit Fusion der beiden Verbandsgemeinden Bad Sobernheim und <a href="http://www.stadt-meisenheim.de/" target="_blank">Meisenheim</a> seit dem Jahr 2019 zur <a href="https://www.vg-nahe-glan.de/" target="_blank">Verbandsgemeinde Nahe-Glan</a>.
        </p>
    </section>
</div>
