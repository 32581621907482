<header class="header">
    <div class="header-title-section">
        <p class="subtitle fade-in">HERZLICH WILLKOMMEN IN</p>
        <p class="divider fade-in">-</p>
        <p class="title fade-in">Merxheim an der Nahe</p>
    </div>
    <img class="header-image" src="../assets/img/DJI_0244.JPG">
    <!--img class="header-image" src="../assets/img/17B361A2-E9A2-4502-BC09-A44F735F2335.JPEG"-->
</header>
<div class="content">
    <section>
      <h2>Aktuelles Dorfgeschehen</h2>
      <p>Auch in einem Dorf wie Merxheim gibt es stets über Neuigkeiten zu berichten. Hier bist Du genau richtig, um stets die aktuellsten Informationen auf einen Blick zu erhalten.</p>
      <app-news-builder></app-news-builder>
      <button [routerLink]="['/aktuelles']" mat-stroked-button>WEITERE NEUIGKEITEN & GESCHEHNISSE</button>
    </section>
    <section>
      <app-events></app-events>
      <button [routerLink]="['/termine']" mat-stroked-button>WEITERE TERMINE & VERANSTALTUNGEN</button>
    </section>
    <section>
      <h2>Herzlich Willkommen</h2>
      <p>
        Es freut mich, Dich auf den Internetseiten der Gemeinde Merxheim begrüßen zu dürfen. <br>
        Zum virtuellen Besuch unserer Gemeinde mittels Internet wünsche ich Dir viel Vergnügen. <br><br>
        Doch auch ein realer Besuch in unserer lebenswerten Gemeinde an der Nahe, zwischen der <br>
        <a href="https://www.idar-oberstein.de/" target="_blank">Edelsteinmetropole Idar-Oberstein</a> und der <a href="https://www.bad-kreuznach.de/" target="_blank">Kurstadt Bad Kreuznach</a> gelegen, lohnt sich für
        Dich. <br>
        Geschichtliche Informationen über unsere Gemeinde erhältst Du unter <a href="/uebermerxheim">„Über Merxheim“</a>. <br><br>
        Ich freue mich auf Deinen Besuch in Merxheim und darauf, Dich vielleicht persönlich begrüßen
        zu können.
      </p>
      <p class="signature">
        Dein <br>
        Egon Eckhardt
      </p>
      <p>Ortsbürgermeister</p>
    </section>
</div>
