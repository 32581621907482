<div class="content">
    <section>
      <h2>Vereine</h2>
      <p>Merxheim bietet jedem ein breit gefächertes Angebot an Vereinen. Ob Du Dich lieber beim Fußball auspowern, oder die Ruhe beim Angeln genießen möchtest - hier ist für jeden etwas dabei. Werde auch Du Mitglied in einem der Merxheimer Vereine.</p>
      <div>
          <mat-card *ngFor="let club of clubs">
            <a href="{{club.url}}" target="_blank">
              <mat-card-title>{{ club.name }}</mat-card-title>
              <mat-card-content>
                  <img src="{{ club.logoPath }}">
              </mat-card-content>
              <mat-card-footer>
                <mat-divider inset></mat-divider>
                <div>
                    <section>
                        <p><mat-icon>person</mat-icon>{{ club.contactPerson }}</p>
                        <p><mat-icon>phone</mat-icon>{{ club.phone }}</p>
                    </section>
                    <section>
                        <p><mat-icon>mail</mat-icon>{{ club.mail }}</p>
                        <p><mat-icon>location_on</mat-icon>{{ club.location }}</p>
                    </section>
                </div>
              </mat-card-footer>
            </a>
          </mat-card>
      </div>
    </section>
</div>
