<div class="content">
    <h1>Impressum</h1>
    
    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>Ortsgemeinde Merxheim<br />
    Schulstraße 2<br />
   55627 Merxheim</p>
        
    <h2>Kontakt</h2>
    <p>Telefon: 06754 665<br />
    E-Mail: bgm@merxheim.de</p>

    <h2>Haftung für eigene Inhalte</h2>
    <p>Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Die Internet-Redaktion der Ortsgemeinde Merxheim behält sich das Recht vor, ohne vorherige Ankündigung die bereitgestellten Informationen zu ändern, zu ergänzen oder zu entfernen.
    Inhaltlich verantwortlich ist gemäß § 6 MDStV der Ortsbürgermeister der Ortsgemeinde Merxheim.</p>

    <h2>Haftung für Links</h2>
    <p>Von diesen eigenen Inhalten sind Querverweise ("Links") auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Auf deren Inhalt haben wir keinen Einfluss; für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
        Falls die Ortsgemeinde Merxheim auf Seiten verweist, deren Inhalt Anlass zur Beanstandung gibt, bitten wir Sie um eine Mitteilung.</p>

    <h2>Urheberrecht</h2>
    <p>Die Webseiten der Ortsgemeinde Merxheim sind urheberrechtlich geschützt. Auf den Webseiten der Ortsgemeinde Merxheim zur Verfügung gestellte Texte, Textteile, Grafiken, Tabellen oder Bildmaterialien dürfen ohne vorherige Zustimmung der Ortsgemeinde Merxheim nicht vervielfältigt, nicht verbreitet und nicht ausgestellt werden.</p>
    
    <h2>Redaktionell verantwortlich</h2>
    <p>Egon Eckhardt<br />
    Ortsb&uuml;rgermeister<br />
    Schulstra&szlig;e 2, 55627 Merxheim</p>

    <h2>Umsetzung und Gestaltung</h2>
    <p>
        Sascha Listner <br>
        Bachstraße 15, 55627 Merxheim
    </p>

    
    <h2>EU-Streitschlichtung</h2>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    
    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    
    <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
</div>
